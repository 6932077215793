<template>
    <div>
        <!-- 铁路订单 -->
        <Railway_Component></Railway_Component>
    </div>
</template>

<script>
import Railway_Component from '../../components/PersonalCenter/Railway_Component.vue'
export default {
    name: "Railway",
    components: { Railway_Component }
}
</script>

<style>

</style>